<template>
  <div class="g08-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-input
            type="text"
            v-model="searchModel.meterageDisplayCode"
            placeholder="子目号"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">查询</el-button>
          <el-button v-if="showAddBtn" type="primary" @click="handleAddClick">添加</el-button>
          <el-button v-if="showAddBtn" type="primary" @click="handleBatchDeleteClick">批量删除</el-button>
          <el-button v-if="showAddBtn" type="primary" @click="handleDeleteAllClick">全部删除</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <my-el-table
      :data="g08List"
      :total="total"
      :page-index="pageIndex"
      :page-size="pageSize"
      :selection="true"
      keyFiled="g08Guid"
      @select-all-change="handleSelectAllChange"
      @selection-change="handleSelectionChange"
      @size-change="handlePageSizeChange"
      @current-change="handlePageIndexChange"
      >
      <el-table-column fixed="left" prop="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column fixed="left" prop="meterageList.meterageDisplayCode" width="130" header-align="left" align="left" label="清单项目编号"></el-table-column>
      <el-table-column fixed="left" prop="meterageList.meterageName" width="260" header-align="left" align="left" label="工程或费用项目名称"></el-table-column>
      <el-table-column fixed="left" prop="meterageList.unit" width="60" header-align="center" align="center" label="单位"></el-table-column>
      <el-table-column fixed="left" prop="meterageList.price" width="100" header-align="center" align="center" label="单价"></el-table-column>
      <el-table-column label="合同数量" header-align="center">
        <el-table-column prop="meterageList.pictureNum" width="120" header-align="center" align="center" label="原合同数量"></el-table-column>
        <el-table-column prop="meterageList.pictureNum" width="120" header-align="center" align="center" label="施工图数量"></el-table-column>
        <el-table-column prop="" width="120" header-align="center" align="center" label="变更数量"></el-table-column>
        <el-table-column prop="meterageList.pictureNum" width="120" header-align="center" align="center" label="变更后数量"></el-table-column>
        <el-table-column prop="meterageList.pictureAmount" width="120" header-align="center" align="center" label="工作量（元）"></el-table-column>
      </el-table-column>
      <el-table-column label="本年度计划工程量" header-align="center">
        <el-table-column
          width="120"
          header-align="center"
          :label-class-name="showEditBtn && !firstYear ? 'editable' : ''"
          align="center"
          label="工程量">
          <template v-if="!scope.row.empty" slot-scope="scope">
            <editable-table-cell
              @blur="handleBlur(scope.row, 0)"
              v-if="showEditBtn && !firstYear"
              v-model="scope.row.currentYearNum">
            </editable-table-cell>
            <span v-else>{{ scope.row.currentYearNum }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="currentYearAmount" width="120" header-align="center" align="center" label="工作量（元）"></el-table-column>
        <el-table-column prop="currentYearPercent" width="120" header-align="center" align="center" label="占合同量%"></el-table-column>
      </el-table-column>
      <el-table-column label="月份计划工程量" header-align="center">
        <el-table-column
          width="80"
          header-align="center"
          :label-class-name="showEditBtn ? 'editable' : ''"
          align="center"
          label="1月">
          <template v-if="!scope.row.empty" slot-scope="scope">
            <editable-table-cell
              @blur="handleBlur(scope.row, 0)"
              v-if="showEditBtn && !scope.row.isLock"
              v-model="scope.row.month1">
            </editable-table-cell>
            <span v-else>{{ scope.row.month1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          header-align="center"
          :label-class-name="showEditBtn ? 'editable' : ''"
          align="center"
          label="2月">
          <template v-if="!scope.row.empty" slot-scope="scope">
            <editable-table-cell
              @blur="handleBlur(scope.row, 0)"
              v-if="showEditBtn && !scope.row.isLock"
              v-model="scope.row.month2">
            </editable-table-cell>
            <span v-else>{{ scope.row.month2 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          header-align="center"
          :label-class-name="showEditBtn ? 'editable' : ''"
          align="center"
          label="3月">
          <template v-if="!scope.row.empty" slot-scope="scope">
            <editable-table-cell
              @blur="handleBlur(scope.row, 0)"
              v-if="showEditBtn && !scope.row.isLock"
              v-model="scope.row.month3">
            </editable-table-cell>
            <span v-else>{{ scope.row.month3 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          header-align="center"
          :label-class-name="showEditBtn ? 'editable' : ''"
          align="center"
          label="4月">
          <template v-if="!scope.row.empty" slot-scope="scope">
            <editable-table-cell
              @blur="handleBlur(scope.row, 0)"
              v-if="showEditBtn && !scope.row.isLock"
              v-model="scope.row.month4">
            </editable-table-cell>
            <span v-else>{{ scope.row.month4 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          header-align="center"
          :label-class-name="showEditBtn ? 'editable' : ''"
          align="center"
          label="5月">
          <template v-if="!scope.row.empty" slot-scope="scope">
            <editable-table-cell
              @blur="handleBlur(scope.row, 0)"
              v-if="showEditBtn && !scope.row.isLock"
              v-model="scope.row.month5">
            </editable-table-cell>
            <span v-else>{{ scope.row.month5 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          header-align="center"
          :label-class-name="showEditBtn ? 'editable' : ''"
          align="center"
          label="6月">
          <template v-if="!scope.row.empty" slot-scope="scope">
            <editable-table-cell
              @blur="handleBlur(scope.row, 0)"
              v-if="showEditBtn && !scope.row.isLock"
              v-model="scope.row.month6">
            </editable-table-cell>
            <span v-else>{{ scope.row.month6 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          header-align="center"
          :label-class-name="showEditBtn ? 'editable' : ''"
          align="center"
          label="7月">
          <template v-if="!scope.row.empty" slot-scope="scope">
            <editable-table-cell
              @blur="handleBlur(scope.row, 0)"
              v-if="showEditBtn && !scope.row.isLock"
              v-model="scope.row.month7">
            </editable-table-cell>
            <span v-else>{{ scope.row.month7 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          header-align="center"
          :label-class-name="showEditBtn ? 'editable' : ''"
          align="center"
          label="8月">
          <template v-if="!scope.row.empty" slot-scope="scope">
            <editable-table-cell
              @blur="handleBlur(scope.row, 0)"
              v-if="showEditBtn && !scope.row.isLock"
              v-model="scope.row.month8">
            </editable-table-cell>
            <span v-else>{{ scope.row.month8 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          header-align="center"
          :label-class-name="showEditBtn ? 'editable' : ''"
          align="center"
          label="9月">
          <template v-if="!scope.row.empty" slot-scope="scope">
            <editable-table-cell
              @blur="handleBlur(scope.row, 0)"
              v-if="showEditBtn && !scope.row.isLock"
              v-model="scope.row.month9">
            </editable-table-cell>
            <span v-else>{{ scope.row.month9 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          header-align="center"
          :label-class-name="showEditBtn ? 'editable' : ''"
          align="center"
          label="10月">
          <template v-if="!scope.row.empty" slot-scope="scope">
            <editable-table-cell
              @blur="handleBlur(scope.row, 0)"
              v-if="showEditBtn && !scope.row.isLock"
              v-model="scope.row.month10">
            </editable-table-cell>
            <span v-else>{{ scope.row.month10 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          header-align="center"
          :label-class-name="showEditBtn ? 'editable' : ''"
          align="center"
          label="11月">
          <template v-if="!scope.row.empty" slot-scope="scope">
            <editable-table-cell
              @blur="handleBlur(scope.row, 0)"
              v-if="showEditBtn && !scope.row.isLock"
              v-model="scope.row.month11">
            </editable-table-cell>
            <span v-else>{{ scope.row.month11 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="month12" width="80" header-align="center" align="center" label="12月"></el-table-column>
      </el-table-column>
      <el-table-column fixed="right" width="70" label="操作" header-align="center" align="center">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <el-tooltip class="item" effect="light" content="修正" :open-delay="500" placement="top">
            <i @click="handleRefineClick(scope.row)" class="el-icon-s-open operation-button" style="margin-right: 10px; font-size: 16px;"></i>
          </el-tooltip>
          <el-tooltip v-if="showAddBtn" class="item" effect="light" content="删除" :open-delay="500" placement="top">
            <i @click="handleDeleteClick(scope.row)" v-if="!scope.row.isLock" class="text-danger far fa-trash-alt operation-button"></i>
            <i v-else class="text-muted far fa-trash-alt operation-button"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </my-el-table>

    <my-dialog
      ref="myDialog"
      title="选择草图"
      size="xl"
      cancel-text="取消"
      ok-text="确定"
      @ok-click="handleOk"
      >
      <picture-index
        ref="pictureIndex"
        :selection="true"
        >
      </picture-index>
    </my-dialog>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import PictureIndex from '@/views/PictureManage/PictureIndex'
import MyElTable from '@/components/MyElTable/MyElTable'
import G08Model from '@/model/G08Model'
import g08Service from '@/services/g08Service'
import PageOperation from '@/mixins/PageOperation'
import PageInfo from '@/support/PageInfo'
import { mapGetters } from 'vuex'
import auth from '@/common/auth'
import config from '@/config'
import EditableTableCell from '@/views/ExaminationManage/Components/EditableTableCell'

export default {
  name: 'G08Index',
  mixins: [
    PageOperation
  ],
  components: {
    PictureIndex,
    MyElTable,
    MyDialog,
    EditableTableCell,
    MySearchBar
  },
  filters: {
  },
  computed: {
    userGuid () {
      return auth.getUserInfo().userGuid
    },
    userTenderGuid () {
      return auth.getUserInfo().tenderGuid
    },
    userIdentityGuid () {
      return auth.getUserInfo().identityGuid
    },
    couldLock () {
      return config.applyIdentities.indexOf(auth.getUserInfo().identityGuid) === -1
    },
    firstYear () {
      // TODO
      return this.year === 2020
    },
    showAddBtn () {
      return this.approvalUserGuid === auth.getUserInfo().userGuid && (config.applyIdentities.indexOf(this.userIdentityGuid) > -1 || config.planStatSuperviseTenderGuids.indexOf(this.userTenderGuid) > -1)
    },
    showEditBtn () {
      return this.approvalUserGuid === this.userGuid
    },
    dataColumns () {
      if (this.g08List.length) {
        return this.g08List[0].children
      }
      return []
    },
    ...mapGetters('planStatExamination', {
      planStatExaminationGuid: 'getPlanStatExaminationGuid',
      tenderGuid: 'getTenderGuid',
      tenderName: 'getTenderName',
      year: 'getYear',
      approvalUserGuid: 'getApprovalUserGuid'
    })
  },
  data () {
    return {
      testValue: 1,
      total: 0,
      g08List: [],
      g08Model: new G08Model(),
      searchModel: {
        planStatExaminationGuid: '',
        meterageDisplayCode: ''
      },
      selectedValues: [],
      totalAmount: ''
    }
  },
  methods: {
    handleDeleteAllClick () {
      this.$confirm('您确定要全部删除吗？')
        .then(() => {
          g08Service.deleteAll(this.tenderGuid, this.year).then(() => {
            this._getG08List()
            this.$message({
              type: 'success',
              message: '删除成功！'
            })
          }).catch((err) => {
            console.log(err)
            this.$message({
              type: 'error',
              message: '删除失败！'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleBatchDeleteClick () {
      if (!this.selectedValues.length) {
        this.$message({
          type: 'warning',
          message: '请先选择要操作的数据！'
        })
        return
      }

      g08Service.batchDelete(this.selectedValues.join(','))
        .then(res => {
          if (res.data.code === 1) {
            this._getG08List()
            this.$message({
              type: 'success',
              message: '删除成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: '操作失败'
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '操作失败'
          })
        })
    },
    classFilter (column) {
      if (column.identityGuid === this.userIdentityGuid && this.userGuid === this.approvalUserGuid) {
        return 'editable'
      }
      return ''
    },
    handleBatchPicture (flag) {
      if (!this.selectedValues.length) {
        this.$message({
          type: 'warning',
          message: '请先选择要操作的数据！'
        })
        return
      }

      if (flag) {
        // open image selection ui
        this.$refs.myDialog.open()
      } else {
        this.doBatchPicture('')
      }
    },
    handleOk () {
      if (!this.$refs.pictureIndex.getSelectedPicture()) {
        this.$message({
          type: 'warning',
          message: '请选择要绑定的草图！'
        })
        return
      }
      this.$refs.myDialog.close()
      this.doBatchPicture(this.$refs.pictureIndex.getSelectedPicture().pictureGuid)
    },
    doBatchPicture (pictureGuid) {
      g08Service.batchPicture(this.selectedValues, pictureGuid)
        .then(res => {
          if (res.data.code === 1) {
            this._getG08List()
            this.$message({
              type: 'success',
              message: (pictureGuid ? '绑定草图' : '删除草图') + '成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: (pictureGuid ? '绑定草图' : '删除草图') + '失败！'
          })
        })
    },
    handleBatchLock (isLocked) {
      if (!this.selectedValues.length) {
        this.$message({
          type: 'warning',
          message: '请先选择要操作的数据！'
        })
        return
      }

      g08Service.batchLock(this.planStatExaminationGuid, this.selectedValues, isLocked)
        .then(res => {
          if (res.data.code === 1) {
            this._getG08List()
            this.$message({
              type: 'success',
              message: (isLocked ? '锁定' : '解锁') + '成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: (isLocked ? '锁定' : '解锁') + '失败！'
          })
        })
    },
    handleRefineClick (row) {
      this.handleBlur(row)
    },
    handleBlur (row, type = 0) {
      if (type) {
        console.log('g08Identity', row)
        this._approval(row)
      } else {
        this.g08Model = new G08Model(row)
        this.g08Model.currentYearNum = parseFloat(row.currentYearNum)
        for (let i = 1; i < 12; ++i) {
          this.g08Model[`month${i}`] = parseFloat(row[`month${i}`])
          if (this.g08Model[`month${i}`] < 0) {
            this.$message({
              type: 'error',
              message: '请检查您的输入！'
            })
            this._getG08List()
            return
          }
        }
        this._edit()
      }
    },
    handleSearchClick () {
      this._getG08List()
    },
    handleAddClick () {
      if (this.tenderGuid === '0f495580-5be7-11ec-9a75-97d8681ca397') {
        this.$router.push('/planstat/g08')
      } else {
        this.$router.push('/g08')
      }
    },
    handleDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let _g08Model = new G08Model(row)
          g08Service.delete(_g08Model.resourceId)
            .then(res => {
              window.console.log(res)
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this._getG08List()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              window.console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleSelectionChange (newVal) {
      this.selectedValues = newVal
      console.log('selectedValues', this.selectedValues)
    },
    handleSelectAllChange (checkAll) {
      this.g08List.forEach(g08 => {
        g08.checked = checkAll
      })
    },
    _getG08List () {
      this.selectedValues = []
      this.searchModel.planStatExaminationGuid = this.planStatExaminationGuid
      g08Service.list(new PageInfo({ pageSize: this.pageSize, pageIndex: this.pageIndex, fieldOrder: '' }), this.searchModel)
        .then(res => {
          if (res.data.code === 1) {
            this.total = res.data.pageCount
            this.g08List = res.data.data.map(g08 => {
              g08.checked = false
              return g08
            })
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          window.console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    _approval (g08Identity) {
      g08Service.approval(g08Identity.g08Guid, g08Identity.identityGuid, g08Identity.num, g08Identity.sortId, this.planStatExaminationGuid, this.periodName)
        .then(res => {
          if (res.data.code === 1) {
            this._getG08List()
            this.$message({
              type: 'success',
              message: '修改数量成功！'
            })
          } else {
            this._getG08List()
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this._getG08List()
          this.$message({
            type: 'error',
            message: '修改数量失败！'
          })
        })
    },
    _edit () {
      g08Service.edit(this.g08Model)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this._getG08List()
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this._getG08List()
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this._getG08List()
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          window.console.log(err.request.response)
        })
    }
  },
  created () {
    this._getG08List()
    this.refreshMethod = this._getG08List
  }
}
</script>

<style scoped lang="scss">
::v-deep ::-webkit-scrollbar {
  display: block;
}
::v-deep ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::v-deep ::-webkit-scrollbar-track {
  border-radius: 15px;
  width: 10px;
}
::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bbb;
  box-shadow: inset 0 0 6px rgba(200, 200, 200, 0.3);
}
::v-deep ::-webkit-scrollbar-thumb:window-inactive {
  background: #eee;
}
::v-deep .editable {
  color: red;
}
</style>
