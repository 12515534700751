import RestService from '@/services/RestService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'g08'

let batchAddUrl = 'batchAdd'
let tenderStatUrl = 'tenderStats'

class G08Service extends RestService {
  batchAdd (g08Models) {
    g08Models.forEach(g08Model => {
      g08Model.generatePrimaryKey()
    })
    return axios.post(utility.getRestFullUrl(this.resourceName, batchAddUrl), g08Models, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getTenderStat (year, projectGuid, engineeringType = '土建') {
    return axios.get(utility.getRestFullUrl(this.resourceName, tenderStatUrl), {
      params: {
        year,
        projectGuid,
        engineeringType
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  deleteAll (tenderGuid, year) {
    return axios.delete(utility.getRestFullUrl(this.resourceName, 'deleteAll', tenderGuid, year), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const g08Service = new G08Service(resourceName)

export default g08Service
